.loader {
    border: 4px solid #1b224c;
    border-left-color: transparent;
    border-radius: 50%;
  }
  .loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1b224c6d;
    width: 100%;
    padding: 10px;
  }
  
  .loader {
    border: 4px solid #1b224c;
    border-left-color: transparent;
    width: 20px;
    height: 20px;
  }
  
  .loader {
    border: 4px solid #1b224c;
    border-left-color: transparent;
    width: 20px;
    height: 20px;
    animation: spin89345 1s linear infinite;
  }
  
  @keyframes spin89345 {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  