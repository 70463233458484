.loader {
  border: 4px solid #1b224c;
  border-left-color: transparent;
  border-radius: 50%;
}
.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.load-main-cont {
  width: 25rem;
  align-items: center;
  display: flex;
  justify-content: center;
}
.loader {
  border: 4px solid #1b224c;
  border-left-color: transparent;
  width: 36px;
  height: 36px;
}

.loader {
  border: 4px solid #1b224c;
  border-left-color: transparent;
  width: 36px;
  height: 36px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
