.refund-table-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  color: #333;
}

h2 {
  font-size: 22px;
  margin-bottom: 10px;
}

.refund-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.refund-table th,
.refund-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.refund-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.refund-table td {
  background-color: #fff;
}

.note {
  margin-top: 20px;
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}


@media screen and (max-width: 900px) {
  .refund-table-container {
    overflow-x: scroll;
  }
}
