* {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  letter-spacing: 0px;
  box-sizing: border-box;
}
body {
  max-width: 2600px;
  margin: auto;
}

/**************************************Buttons*****/
.aud-button {
  /* width: 100%; */
  min-width: 100px;
  display: flex;
  white-space: nowrap;
  border-radius: 5px;
  padding: 15px 16px;
  background-color: #1b224c;
  color: #ffffff;
  border: none;
  font-size: 15px;
  font-weight: 600;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  height: 34px;
}

.aud-button-disabled {
  background-color: grey;
  color: #ffffff;
}
.aud-button > .button-icon {
  vertical-align: middle !important;
  width: 25px !important;
}
.showinterest-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.4rem;
}
a {
  text-decoration: none !important;
}
.aud-button:hover {
  background-color: #f3223c;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.aud-button-disabled:hover {
  background-color: rgb(94, 94, 94);
}
.blue-link a {
  color: #3886bd;
  text-decoration: underline !important;
}
/************/
.span-required {
  color: #f3223c;
  font-weight: 500;
  font-size: 15px;
}
/******************************************Radio Buttons*****/
.radio-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-input label {
  color: #1b224c;
  font-size: 17px;
  font: 600;
}

.radio-input input {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* transition: .4s ease-in-out; */
  border: 2px solid #113161;
  cursor: pointer;
}

.radio-input input:hover + .circle {
  /* scale: 1.2; */
  cursor: pointer;
  box-shadow: none;
}

.radio-input .circle {
  position: relative;
  top: 0em;
  right: 0.95em;
  width: 10px;
  height: 10px;
  background-color: #6f70705d;
  scale: 0;
  border-radius: 10px;
  transition: 0.4s ease-in-out;
  cursor: pointer;
}

.radio-input input:checked {
  box-shadow: none;
  border: 2px solid #113161;
}

.radio-input input:checked + .circle {
  background-color: #113161;
  scale: 0.9;
}

.radio-buttons-container {
  display: flex;
  gap: 2rem;
}
.radioBtn-label {
  color: #1b224c;
  /* text-transform: capitalize; */
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
}
/****************************************************TextBox**/
input[type='date'] {
  color: transparent;
}
input[type='date'] {
  font-weight: 400;
}
.authorize-class {
  color: #f3223c;
}
.text-input {
  background-color: transparent;
  border-radius: 5px;
  height: 38px;
  border: 1px solid hsl(0, 0%, 80%);
  color: #1b224c !important;
  font-weight: 500;
  padding: 5px;
}
.text-input input[type='text'] {
  color: #1b224c !important;
}
.text-input::placeholder {
  display: none;
}
.text-input-error {
  background-color: transparent;
  border-radius: 5px;
  height: 38px;
  border: 1px solid #f3223c;
  /* opacity: 0.2; */
  padding: 5px;
}
input[type='text']:hover {
  border: 0.5px solid #1b224c5c;
  transition: 0.2s ease-in-out;
}
input:focus-visible {
  outline: 0.8px solid #1b224c98;
  border: none;
}
.checkbox-container p {
  margin-bottom: 0;
}
label {
  font-weight: 600;
  font-size: 15px;
  color: #1b224c;
}
textarea:hover {
  border: 0.5px solid #1b224c5c;
  transition: 0.2s ease-in-out;
}
textarea:focus-visible {
  outline: 0.8px solid hsl(0, 0%, 80%);
  border: none;
}
.required {
  color: #f3223c;
  padding-left: 1px;
  font-size: 20px;
  font-weight: bold;
}

.textBox-container {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  position: relative;
}
.textBox-container-error {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.textBox-container label {
  font-weight: 600;
  font-size: 17px;
  /* white-space: nowrap; */
}
.moreInfo-img {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  margin-right: 2px;
  margin-bottom: 6px;
}
/************************************************** DropDown*******/

.css-tj5bde-Svg {
  display: none !important;
}
.custom-dropdown-arrow {
  color: #1b224c;
  width: 12px;
  padding-right: 15px;
}
.css-qbdosj-Input:hover {
  border: none;
}

/***************************************************Text with line*****/
.title-with-line {
  display: flex;
  align-items: center;
  /* gap: 3px; */
}
.title-with-line span {
  color: #f3223c;
  font-size: 17px;
  font-weight: 800;
}
.line {
  flex-grow: 1;
  height: 0px;
  opacity: 0.4;
  border: 1.8px solid #1b224c;
  margin-left: 8px;
}

.dotted-line {
  flex-grow: 1;
  height: 1.5px;
  background: linear-gradient(
    90deg,
    #1b224c49 50%,
    transparent 50%,
    transparent
  );
  background-size: 25px 1px;
  margin-left: 10px;
}

.title {
  color: #1b224c;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Optional - if you want to remove the space */
}
/****************************************Rounded button********/
.rounded-button {
  padding: 10px;
  border: none;
  font-size: 15px;
  font-weight: 500;
  color: white;
  border-radius: 28px;
  opacity: 1;
  background-color: #3886bd;
  width: fit-content;
}

.button-icon {
  padding-right: 5px;
}

.rounded-button:hover {
  background-color: #f3223c;
  cursor: pointer;
}

/******************************************file input********/
.file-input {
  display: none;
}

.file-input-container-upl,
.file-input-container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 5px;
  cursor: pointer;
}
.file-input-container-upl {
  background-color: #020b581f;
}
.img-text-container {
  width: 70%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.img-text-container p {
  /* max-width: 110px; */
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1b224c;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 1; */
}
.size-upload-dc p {
  padding-right: 5px;
  color: #1b224c;
  text-align: right;
  font-family: Raleway;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.close-document-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
}
.smallImage {
  height: 25px;
}
.bigImage {
  height: 50px;
}
/**************************************Modal****/
.modal-container {
  position: fixed;
  padding: 30px 50px;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0000005a;
  width: 100%;
  height: 100vh;
  z-index: 4;
}

.inner-modal-container {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 30px 30px;
  animation: slide-up-fade-in 0.3s ease-out forwards;
}

@keyframes slide-up-fade-in {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.inner-modal-container .close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  font-size: 20px;
  opacity: 1;
}
.close-icon img {
  width: 40px;
}
.close-icon img:hover {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
.inner-modal-container h2 {
  font-size: 22px;
  color: #020a58;
  font-weight: bold;
}

.inner-modal-container .submit-modal-button {
  align-self: flex-end;
  padding-right: 50px;
}

/****************************************Text component ***/
.p-style {
  font-weight: 500;
  margin-bottom: 0;
  color: #1b224c;
  font-weight: 18px;
  letter-spacing: 0px;
}

/**********************************Collapsed container*****/
.collapsed-container {
  width: 100%;
  background-color: #020a581a;
  padding: 15px 29px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
}
.roundedRadius {
  border-radius: 5px 48px 48px 5px;
}

.title-hover:hover {
  color: #f3223c !important;
}
.expandable-card {
  width: 100%;
  /* background-color: #020a581a; */
  padding: 15px 5px;
  margin: 10px 0;
  /* cursor: pointer; */
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.close-icon-exp {
  z-index: 1;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  font-size: 20px;
  /* opacity: 1; */
}
.card {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.card-content {
  max-height: 0;
  opacity: 0;
}
.expanded {
  max-height: 700px;
  opacity: 1;
}
.expand-font-title {
  font-size: 20px;
}
/***********************************Phone Input****/
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: transparent !important;
  border: 1px solid #1b224c31;
  border-radius: 5px;
  line-height: 25px;
  height: 30px;
  width: 343px;
  outline: none;
  color: #1b224c !important;
  font-weight: 500 !important;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: #f2f2f2;
  border-radius: 3px 0 0 0;
}

.react-tel-input .form-control {
  border: 1px solid #1b224c31;
  height: 38px !important;
}
.react-tel-input .form-control:focus-visible {
  outline: 0.8px solid #1b224c;
}

.phone-input-25 {
  width: 25% !important;
}

.phone-input-50 {
  width: 50% !important;
}

.phone-input-100 {
  width: 100% !important;
}

/***********************************Link*****/
.linktext {
  color: #f3223c;
  font-size: 16px;
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;

  text-decoration: none;
}

.underlined-link {
  text-decoration: underline !important;
  color: #f3223c;
  font-size: 16px;
  font-weight: bold;
  padding-left: 5px;
  cursor: pointer;
}
.blue-link {
  color: #3886bd;
  /* text-transform: capitalize;
  font-weight: bold; */
  font-size: 18px;
  padding-left: 5px;
  cursor: pointer;
}
.blue-link span {
  font-size: 18px;
  font-weight: 600;
  color: #1b224c;
}
.cursor {
  cursor: pointer;
}
/****************************************8***Bulleted text******/
li::marker {
  color: #1b224c;
  font-size: 25px;
}

ul.circle-bullets {
  list-style-type: circle;
}

ul {
  font-size: 18px;
  color: #1b224c;
  font-weight: 600;
  padding-left: 1.5rem !important;
}

/**************************************88**Custom Checkbox******/

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 0px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid #113161;
  border-radius: 3px;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #113161;
}

.checkbox-container:hover input ~ .checkmark {
  border: 1px solid #f3223c;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkbox-container p {
  font-size: 18px;
  font-weight: 600;
  padding-left: 5px;
}
/*************red checkmark*************************/
.red-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid #f3223c;
  border-radius: 3px;
}

.checkbox-container input:checked ~ .red-checkmark {
  background-color: #f3223c;
}

.checkbox-container:hover input ~ .red-checkmark {
  border: 1px solid #f3223c;
}

.red-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .red-checkmark:after {
  display: block;
}

.checkbox-container .red-checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*********************************************textarea*****/
.textBox-container textarea {
  resize: none;
  border-radius: 5px;
  border: 1px solid hsl(0, 0%, 80%);
}
.textBox-container textarea:hover {
  border: 0.5px solid #1b224c5c;
  transition: 0.2s ease-in-out;
}

.textBox-container-error textarea {
  resize: none;
  border-radius: 5px;
  border: 1px solid #f3223c;
}
/******************************stepper***/

.stepper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.subStep-container {
  display: flex;
  align-items: center;
}

.stepper .step {
  padding: 3px 15px;
  margin: 0 10px 0px 10px;
  color: #ffffff;
  height: 40px;
  border-radius: 50%;
  width: 40px;
  transition: 0.5s all ease-in-out;
}

.activeLine {
  width: 0;
  height: 3px;
  opacity: 0;
  background-color: white;
  margin: 0 0.5rem;
}

.inActiveLine {
  width: 0;
  height: 3px;
  opacity: 0.4;
  width: 45px;
  background-color: white;
  margin: 0 10px;
}

.stepper .step.active ~ .activeLine {
  width: 100px;
  opacity: 1;
}

.active {
  background-color: white;
  transform: scale(1);
}

.inactive {
  background-color: rgba(135, 135, 135, 0.555);
}

.checkedLogo {
  padding: 0rem 10px;
  height: 40px;
  opacity: 0.4;
}
.stepper-font-number-inact {
  font-size: 20px;
}
.stepper-font-number-title {
  font-size: 14px;
}
/******select react*******/
.css-tj5bde-Svg {
  color: #1b224c;
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.css-1jqq78o-placeholder {
  display: none;
}

.css-13cymwt-control {
  background-color: transparent !important;
}

.css-g37w59-control {
  border-color: #1b224c !important;
}

.css-t3ipsp-control {
  border-color: #1b224c !important;
  box-shadow: none !important;
}
.css-zso92v-control {
  border-color: #1b224c !important;
  box-shadow: none !important;
}
.css-zso92v-control:focus {
  border-color: #1b224c !important;
  box-shadow: none !important;
}
.css-zso92v-control:focus-visible {
  border-color: none !important;
}
.css-ugifqb-control:hover {
  border: none !important;
}
.css-ugifqb-control {
  border: none !important;
  outline: none;
}
/************************************Show interest*****/
.showInterest-container {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: 'translate(-50%,-50%';
  z-index: 10;
  width: 100%;
  height: 100dvh;
}

.showInterest-subContainer {
  position: relative;
  padding: 15px 50px;
  background-color: #ffffff;
  border-radius: 10px;
  max-height: 95dvh;
  width: 87%;
  display: flex;
  flex-direction: row;
  opacity: 0.9;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  align-items: center;
  gap: 5vw;
  /* justify-content: space-between; */
  /* gap: 5%; */
  /* overflow: auto; */
  box-sizing: border-box;
}

.showInterestLogo {
  width: 38%;
}
#background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.classfont-show {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
  color: #1b224c;
  font-weight: 18px;
  letter-spacing: 0px;
}
.applicant-loginp {
  font-size: 16px !important;
  padding-top: 5px;
}
.applicant-loginp span {
  cursor: pointer;
  color: #3886bd;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: capitalize;
  text-decoration: underline;
}
.button-cont-register {
  gap: 0.5rem;
  display: flex;
  margin-left: auto;
}
/***************************************verticsl line*****/
.vertical-container {
  max-height: 80%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.vertical {
  width: 2px;
  height: 85vh;
  background: linear-gradient(
    170deg,
    #1b224c49 50%,
    transparent 50%,
    transparent
  );
  background-size: 1px 20px;
}

/****************************************Horizantal line*****/
.horizontal-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.horizontal {
  width: 75vw;
  background: transparent;
  border-top: 2px dashed rgba(0, 0, 0, 0.23);
}

/*********************************8***Show Interest Form*********/
.show-interest-form {
  flex: 1 1;
  max-height: 87dvh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 20px;
}

.showInterestForm-inner-cont {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.grid-container {
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  grid-column-gap: 10px;
}
.showInterestForm-inner-cont p {
  color: #1b224c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.grid-container2 {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-column-gap: 10px;
}
.grid-container3 {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-column-gap: 10px;
  align-items: start;
}
.showInterestForm-inner-cont .error-span {
  border-radius: 4px;
  background-color: #fff !important;
  opacity: 1 !important;
  padding: 6px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
.error-container {
  background-color: #fff;
  opacity: 1 !important;
  padding-top: 5px;
  display: flex;
  gap: 2px;
  align-items: flex-start;
}
.error-container p {
  font-size: 12px;
  color: #c60303;
}
.error-container span {
  color: #1b224c;
}

.select-via-email {
  height: 80px;
  border-radius: 9px;
  background: #f0eeee;
  padding: 25px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  cursor: pointer;
}
.select-via-email:hover {
  box-shadow: 0px 3px 6px #00000029;
  transition: 0.2s ease-in-out;
}
.select-via-email:hover h3 {
  color: #f3223c !important;
  transition: 0.2s ease-in-out;
}
.select-via-email:hover img {
  filter: brightness(0) saturate(100%) invert(23%) sepia(95%) saturate(5309%)
    hue-rotate(344deg) brightness(102%) contrast(91%);
}
.select-via-email h3 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.select-via-email h4 {
  color: #1b224c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.forgort-password-cont {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 25px;
}
.otp-field {
  width: 50px;
  height: 50px;
  font-size: 18px;
  text-align: center;
  border: 0.2px solid #ccc;
  border-radius: 5px;
  outline: none;
  background-color: #f0eeee;
}
.otpField-container {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}

.gif {
  height: 170px;
  width: 170px;
}
/***********************************Upper Header*************/
.upperHeader-container {
  /* position: fixed; */
  height: 84px;
  width: 100%;
  background-color: #1b224c;
}

/******************************Aud app header*************/
.aud-app-header {
  background-color: #1b224c;
  width: 100%;
  height: 84px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
}

/* .aud-header-logo {
  width: 150px;
  height: 30px;
} */
.exit-container {
  max-width: 40rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
/*******************************Register Page***************/
.registerPage-container {
  display: flex;
  flex-direction: column;
  margin: 7rem 2rem;
  gap: 1.5rem;
}
.register-title {
  font-size: 20px;
}
.back-button {
  align-items: flex-start;
  width: 200px;
}

/**************************8****Register forms container*********/
.registerForms-container {
  border-bottom: 1px solid #1b224c66;
  border-left: 1px solid #1b224c66;
  border-right: 1px solid #1b224c66;
  padding: 4rem 3rem;
}
.register-LoaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.form-subcontainer {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.form-subcontainers {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.required_residence {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.residenceCont {
  display: flex;
  flex-direction: row;
  gap: 5rem;
  align-items: center;
}
/***********************************Personal Information********/
.grid-personal1-cont {
  display: grid;
  grid-template-columns: 2fr 3fr 3fr 3fr;
  grid-column-gap: 10px;
}
.grid-personal2-cont {
  display: grid;
  grid-template-columns: 3fr 3fr 2fr 3fr;
  grid-column-gap: 10px;
}
/********text editor **/
.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  min-height: 150px;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.words-number {
  font-size: 14px;
  font-weight: 400;
}
.required-field {
  color: #f3223c;
}
/**********************************Mailing Address*************/
.grid-mailing-cont {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
}
.grid-personal-emirates-cont {
  display: grid;
  grid-template-columns: 2fr 3fr 3fr 3fr;
  grid-column-gap: 10px;
}

/****************************PROGRAM INFORMATION*******************/
.grid-programInfo-cont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
.grid-programInfo-subcont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.classfont-p {
  color: #1b224c;
  font-size: 18px;
}
.moreInfo-grid {
  display: flex;
  flex-direction: column;
  color: #000;
  font-family: Raleway;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.moreInfo-grid a {
  cursor: pointer;
  color: #3886bd !important;
  font-family: Raleway;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline !important;
}
/************************************Consent****************/
.grid-consent1-cont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
}
.grid-consent2-cont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
}
.consent-text {
  font-size: 18px;
}
.bulleted-consent-grid {
  display: flex;
  justify-content: space-between;
}
/*****************************Academic information***************/
.grid-academic-cont {
  display: grid;
  grid-template-columns: 3fr 4fr 3fr 2fr;
  grid-column-gap: 10px;
}

.grid-academic2-cont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
}
.grid-academic4-cont {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}
.transcript-mailing-cont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
}
.radioButton_removeIcon_cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.remove_text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.1rem;
  cursor: pointer;
}
.remove_text h6 {
  margin: 0;
  color: #ea3a3d;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
/*********************************Regulation table******************/
.regulations-container {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1b224c66;
  border-radius: 5px;
}
.regulations-header {
  height: 60px;
  background: var(--unnamed-color-1b224c) 0% 0% no-repeat padding-box;
  background: #1b224c2b 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  font-weight: 800;
  color: #1b224c;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.reg-sub-cont-header {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}
/*******StudentInfo***********/
.studentInfo-subCont {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 10px;
}
/***************************Agreement table***************/
table {
  border: none;
  border-collapse: collapse;
  width: 100%;
}

th {
  background-color: #1b224c1e;
  color: #1b224c;
  font-size: 16px;
  font-weight: 800;
  text-align: left;
}

td {
  color: #1b224c;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 10px;
}
thead {
  text-align: center;
}
/* .applications-table thead {
  position: sticky;
  top: -7px;
  z-index: 20;
} */
thead tr th:first-child {
  text-align: left;
  padding: 20px 30px;
}

td:first-child {
  text-align: left;
  padding-left: 30px;
}
.cont {
  overflow: scroll;
  max-height: 75vh;
}
.custom-date-input {
  position: relative;
  border-radius: 5px;
  height: 38px;
  border: 1px solid hsl(0, 0%, 80%);
  padding: 5px;
  overflow: hidden;
}
.classfont-agreement {
  font-size: 18px;
}
.custom-date-input:hover {
  border: 0.5px solid #1b224c5c;
  transition: 0.2s ease-in-out;
}
.custom-date-input input[type='date'] {
  position: absolute;
  top: 4px;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 85%;
  color: #1b224c;
  font-weight: 500;
  padding: 10px;
  border: transparent;
  background-color: transparent;
}

.custom-date-input input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
}

.custom-date-input input[type='date']:focus-visible {
  outline: transparent;
  border: none;
}
/*************************test******************/
.custom-date-input-red {
  position: relative;
  border-radius: 5px;
  height: 38px;
  border: 1px solid #f3223c;
  padding: 5px;
  overflow: hidden;
}

.custom-date-input-red:hover {
  border: 0.5px solid #1b224c5c;
  transition: 0.2s ease-in-out;
}
.custom-date-input-red input[type='date'] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 85%;
  cursor: pointer;
  color: #020a58;
  font-weight: 600;
  padding: 10px;
  border: transparent;
  background-color: transparent;
}

.custom-date-input-red input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.custom-date-input-red input[type='date']:focus-visible {
  outline: transparent;
  border: none;
}
/**********************************************/

.custom-date-icon {
  position: absolute;
  right: 10px;
  top: 2;
  width: 20px;
  height: 20px;
  background: url('./../public/images/calendar4.svg') no-repeat;
  background-size: cover;
  cursor: pointer;
  overflow: hidden;
}

/**********************************Agreement*******/
.agreement-table-cont {
  border: 1px solid #1b224c66;
  padding-bottom: 35px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 5px;
}

.ag-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 30px;
}
.agreement-container {
  padding: 2% 0;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.agreement-title {
  background-color: #1b224c1e;
  padding-left: 10px;
  padding-right: 10px;
}
/***********************************Academic files*********/
.grid-acd-cont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  grid-column-gap: 10px;
}
/*********************************Tuition******/
.tuition-container {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  /* padding: 0 50px 0 50px; */
}

/*************************************Registration page 4*************/
.payment-container {
  display: flex;
  flex-direction: row;
  padding: 0 10%;
  justify-content: space-between;
  gap: 1rem;
}
.payment-subcontainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.payment-subcontainer p {
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 0px;
}
/***************************************Payment***************/

.tab-container {
  border: 1px solid #1b224c27;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 45px;
}

.active-tab {
  font-size: 18px;
  color: #1b224c;
  font-weight: bold;
  background-color: #39a3003c;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  margin: 3px;
  width: 250px;
  text-align: center;
}
.tab-container a {
  text-decoration: none !important;
}
.inactive-tab {
  font-size: 18px;
  color: #1b224c;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 250px;
  text-align: center;
}
.stroke {
  width: 0px;
  height: 24px;
  border: 1px solid #000000;
  opacity: 0.2;
}
.card_dropdown_cont {
  display: flex;
  gap: 2px;
}
/*********************************Card type********/
.activeType {
  background-color: #39a3003c;
  border: 1px solid #00000033;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.inActiveType {
  border: 1px solid #00000033;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

/***********************************Invoice**********/
.invoice-container {
  background-image: url('../public/images/invoice-image.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 500px;
  width: 455px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.invoice-title {
  align-self: center;
}
.app-fee-cont {
  margin-bottom: 20px;
  margin-left: 20px;
  /* font-size: 18px; */
}
.app-fee-cont label {
  font-size: 20px;
  font-weight: 600;
}
.app-fee-cont div {
  font-size: 30px;
  font-weight: bold;
}
.app-details {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
}
.app-details div:first-child {
  color: #1b224c;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 500;
}
.app-details div:nth-child(2) {
  color: #1b224c;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: bold;
}
.invoice-card-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.verticalPayment-container {
  /* height: 10vh; */
  display: flex;
  align-items: center;
}
.verticalLine {
  width: 2px;
  height: 500px;
  background: transparent;
  border-left: 2px dashed rgba(0, 0, 0, 0.23);
}

/************************************Login container**************/
.login-container {
  padding-top: 1.8rem;
  /* padding-left: 50px;
  padding-right: 50px; */
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
}
.login-container .test222 {
  align-self: center;
}

/*****************Applications table****/

.applications-table-cont {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 70vw;
}
.applications-table-cont .cont div:nth-child(2) {
  align-self: center;
}
.applications-table-cont table {
  border: 1px solid #e4e5e9;
  border-collapse: collapse;
  width: 100%;
}

.applications-table-cont table thead tr th {
  padding: 10px 5px;
}
.applications-table-cont th {
  background-color: #1b224c1e;
  color: #1b224c;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
}

.applications-table-cont td {
  color: #1b224c;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  text-align: start;
  padding: 10px 5px;
  /* white-space: nowrap; */
  /* white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.applications-table-cont tr:nth-child(even) {
  border-radius: 10px;
  background-color: #fafafc;
}

.applicationModal_text_cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 3px;
  gap: 5px;
  justify-content: flex-end;
}

.applicationModal_text_cont.reapply {
  justify-content: normal;
}
.applicationModal_text_subcont {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

@media screen and (max-width: 900px) {
  .cont {
    max-width: 90vw;
    overflow-x: auto;
  }
  .applications-table {
    overflow-x: scroll;
  }
  .applicationModal_text_cont {
    flex-wrap: wrap;
    gap: 4px;
  }
  .applicationModal_text_subcont {
    flex-wrap: wrap;
    gap: 2px;
  }
}
/****************Media Query****************************/
/**************************************/
/*****************************/
/************************/
/*****************/
/*********/
/***/

/************SHOW INTEREST MEDIA QUERY*/

@media screen and (max-width: 1733px) {
  .show-interest-form {
    gap: 10px;
  }
  .showInterestForm-inner-cont {
    gap: 5%;
  }
  .radio-buttons-container {
    gap: 2%;
  }
  .radio-buttons-container label {
    font-size: 15px;
  }
  .textBox-container label {
    font-size: 15px;
  }
}
@media screen and (max-width: 1750px) {
  .show-interest-form {
    gap: 5px;
  }
}
@media screen and (max-height: 694px) {
  .showInterestLogo {
    width: 30%;
  }
  .show-interest-form {
    gap: 0px;
  }
  .show-interest-form {
    width: 70%;
    gap: 10px;
    /* padding: 20px; */
    padding: 0 10px 0 10px;
  }
  .title {
    font-size: 15px;
  }
  .textBox-container label {
    font-size: 12px;
  }
  label {
    font-size: 12px;
  }
  .radio-buttons-container label {
    font-size: 12px;
  }
  .underlined-link {
    font-size: 15px;
  }
  .linktext {
    color: #f3223c;
    font-size: 12px;
  }
  .aud-button {
    padding: 10px;
    font-size: 15px;
  }
  .classfont-show {
    font-size: 18px;
  }
  .required {
    font-size: 15px;
  }
}
/****TABLET Show Interest **************************/
/*****************************/
@media screen and (max-width: 1519px) {
  .showInterestLogo {
    width: 30%;
  }
  .show-interest-form {
    width: 60%;
  }
}

@media screen and (max-width: 1700px) and (min-height: 900px) {
  .show-interest-form {
    gap: 20px;
    max-height: 90dvh;
  }
  .showInterestForm-inner-cont {
    gap: 1vh;
  }
}
@media screen and (max-width: 1280px) {
  .showInterest-subContainer {
    padding: 30px 30px;
    /* height: 89%; */
    width: 95%;
    flex-direction: column;
    /* justify-content: center;
    gap: 5%; */
  }
  .vertical-line-showInterest {
    display: none;
  }
  .show-interest-form {
    width: 100%;
    gap: 10px;
    padding: 0 10px 0 10px;
  }
  .radio-buttons-container {
    gap: 8px;
  }
  .radio-input label {
    font-size: 15px;
  }
  .showInterestLogo {
    width: 45%;
  }
  .inner-modal-container {
    padding: 30px 20px;
  }

  /**buttons**/
  .showinterest-btn-container {
    margin-top: 1rem;
  }

  .showInterestForm-inner-cont p {
    padding-top: 10px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) and (min-height: 615px) and (max-height: 835px) {
  .showInterestLogo {
    width: 70vw;
  }
  .show-interest-form {
    width: 70%;
    gap: 2px;
    padding: 0 10px 0 10px;
  }
  .showInterest-subContainer {
    padding: 30px 20px;
    /* height: 98%; */
    /* gap: 2%; */
    width: 95%;
    flex-direction: column;
  }
  .vertical-line-showInterest {
    display: block;
  }
  .classfont-show {
    font-size: 20px;
  }
}

@media screen and (max-width: 1070px) and (max-height: 635px) {
  .showInterest-subContainer {
    padding: 30px 20px;
    /* height: 98%; */
    /* gap: 1%; */
    width: 90%;
    flex-direction: row;
  }
  .showInterestLogo {
    width: 70vw;
  }

  .required {
    font-size: 15px;
  }
}

@media screen and (max-width: 1024px) and (max-height: 652px) {
  .showInterest-subContainer {
    padding: 30px 20px;
    /* height: 98%; */
    /* gap: 1%; */
    width: 90%;
    flex-direction: column;
  }
  .showInterestLogo {
    width: 70vw;
  }
  .required {
    font-size: 15px;
  }
  .vertical-line-showInterest {
    display: none;
  }
}
@media screen and (max-width: 1280px) and (max-height: 850px) {
  .showInterest-subContainer {
    padding: 30px 30px;
    /* height: 95%; */
    width: 95%;
    flex-direction: row;
    /* justify-content: center;
    gap: 5%; */
  }
  .vertical-line-showInterest {
    display: block;
  }
  .show-interest-form {
    width: 100%;
    gap: 10px;
    padding: 0 10px 0 10px;
    /* overflow-y: scroll;
    max-height: 100%; */
  }
  .radio-buttons-container {
    gap: 8px;
  }
  .radio-input label {
    font-size: 15px;
  }
  .showInterestLogo {
    width: 30%;
  }
}

@media screen and (max-width: 1022px) and (max-height: 730px) {
  .showInterest-subContainer {
    padding: 30px 30px;
    /* height: 95%; */
    width: 95%;
    flex-direction: column;
    /* justify-content: center;
    gap: 1%; */
  }
  .vertical-line-showInterest {
    display: none;
  }

  .classfont-show {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    color: #1b224c;
    font-weight: 18px;
    letter-spacing: 0px;
  }
}
@media screen and (max-width: 880px) and (max-height: 850px) {
  .vertical-line-showInterest {
    display: none;
  }
  .showInterestForm-inner-cont {
    gap: 0.2vh;
  }
}

/******************************************/
/********************************/
/****************/
/*********/

/************Stepper MEDIA QUERY*/
@media screen and (max-width: 1507px) {
  .inActiveLine {
    width: 25px;
  }
  .stepper .step.active ~ .activeLine {
    width: 95px;
  }
}
@media screen and (max-width: 1371px) {
  .inActiveLine {
    width: 24px;
    margin: 0;
  }
  .stepper .step.active ~ .activeLine {
    width: 70px;
    /* margin: 0; */
  }
  .stepper-font-number-title {
    font-size: 10px;
  }
  .react-tel-input .form-control {
    font-size: 14px;
  }
}
@media screen and (max-width: 1259px) {
  .registerPage-container {
    margin: 2rem;
  }
  .inActi .stepper-font-number-title {
    font-size: 10px;
  }
  .stepper .step.active ~ .activeLine {
    width: 85px;
  }
  .stepper .step {
    padding: 0px 7px 2px 7px;
    margin: 0 5px 0px 5px;
    height: 24px;
    width: 24px;
  }
  .stepper-font-number-inact {
    font-size: 17px;
  }
  .activeLine {
    margin: 0px 0.2rem;
  }
  .checkedLogo {
    height: 25px;
  }
}
@media screen and (max-width: 897px) {
  .stepper-font-number-title {
    font-size: 9px;
  }
  .inActiveLine {
    width: 18px;
    height: 1.5px;
  }
  .stepper .step.active ~ .activeLine {
    width: 28px;
    height: 1.5px;
  }
  .stepper-font-number-inact {
    font-size: 12px;
  }
  .stepper .step {
    padding: 0px 6px 3px 6px;
    margin: 0px 2px 0px 2px;
    height: 18px;
    width: 18px;
  }
}

/******************************************/
/********************************/
/****************/
/*********/
@media screen and (max-width: 1630px) {
  .academic-container .textBox-container label {
    font-size: 12px;
  }
  .academic-container label {
    font-size: 12px;
  }
  .file-input-container p {
    font-size: 13px;
  }
}
@media screen and (max-width: 1490px) {
  .app-details div:first-child {
    font-size: 12px;
  }
  .app-details div:nth-child(2) {
    font-size: 12px;
  }
  .app-details {
    padding: 0px 10px;
  }
  .app-fee-cont label {
    font-size: 12px;
  }
  .app-fee-cont div {
    font-size: 18px;
  }
  .app-fee-cont {
    margin-bottom: 40px;
    margin-left: 20px;
  }
  .invoice-card-container {
    padding-bottom: 30px;
  }
  .applications-table-cont th,
  .applications-table-cont td {
    font-size: 12px;
  }
  .transcript-mailing-cont {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 1437px) {
  .invoice-card-container {
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 1400px) {
  .payment-container {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 5%;
    justify-content: space-between;
    gap: 0.5rem;
    align-items: center;
  }
  .payment-verticalline {
    display: none;
  }
  .invoice-container .title,
  .payment-subcontainer .title {
    font-size: 18px;
  }

  .invoice-container {
    height: 350px;
    width: 250px;
  }
  .app-details div:first-child {
    font-size: 12px;
  }
  .app-details div:nth-child(2) {
    font-size: 12px;
  }
  .app-details {
    padding: 0px 10px;
  }
  .app-fee-cont label {
    font-size: 12px;
  }
  .app-fee-cont div {
    font-size: 18px;
  }
  .app-fee-cont {
    margin-bottom: 40px;
    margin-left: 20px;
  }
  .payment-subcontainer p {
    font-size: 12px;
  }
  .active-tab {
    font-size: 12px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .inactive-tab {
    font-size: 12px;
  }
  .payment-subcontainer .textBox-container label {
    font-size: 12px;
  }
  .payment-subcontainer {
    gap: 0.8rem;
  }
  .register-title {
    font-size: 14px;
  }
  .invoice-card-container {
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 1259px) {
  /*************Aud header MEdia query**************************************/
  .aud-header-logo {
    width: 100px;
    height: 20px;
  }
  .aud-app-header {
    background-color: #1b224c;
    width: 100%;
    height: 84px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .upperHeader-container {
    height: 60px;
  }

  /************Step 1 MEDIA QUERY***************************************/
  .registerForms-container {
    padding: 4rem 1rem;
  }
  .form-subcontainers .title,
  .tuition-container .title {
    font-size: 13px;
  }

  .form-subcontainers .textBox-container label {
    font-size: 12px;
  }
  .form-subcontainers ul {
    font-size: 12px;
  }
  .consent-text {
    font-size: 12px;
  }
  .form-subcontainers .linktext {
    font-size: 13px;
  }
  .form-subcontainers label {
    font-size: 12px;
  }
  /************Step2 MEDIA QUERY***************************************/
  .submittext-container ul li {
    font-size: 12px !important;
  }
  .consent-text {
    font-size: 12px;
  }
  .file-input-container .p-style {
    font-size: 12px !important;
  }
  .rounded-button {
    padding: 10px;
    font-size: 10px;
  }
  .grid-academic-cont {
    grid-template-columns: 3fr 3fr 3fr 3fr;
  }
  .grid-acd-cont {
    grid-template-columns: 2fr 2fr 2fr 2fr;
  }
  li::marker {
    color: #1b224c;
    font-size: 15px;
  }
  /************Step3 MEDIA QUERY***************************************/
  .checkbox-container p {
    font-size: 12px;
  }
  .important-notices-container ul li,
  .agreement-container ul li {
    font-size: 12px !important;
  }
  .blue-link {
    font-size: 12px;
  }
  .blue-link span {
    font-size: 12px;
  }

  th,
  td {
    font-size: 9px;
  }
  .classfont-agreement {
    font-size: 12px;
  }
  .classfont-p {
    font-size: 14px;
  }
  .agreement-container .required {
    font-size: 15px;
  }
  .modal-title-3font {
    font-size: 20px !important;
  }
  .custom-date-input input[type='date'] {
    font-size: 12px;
  }
  .login-container .underlined-link {
    font-size: 12px;
  }
  .title-with-line span {
    font-size: 13px;
  }
  .expand-font-title {
    font-size: 14px;
  }
  /******step4 media query********************************/
}

/************************************************************/
/*********************************/
/**************************/
/********Mobile
responsiveness ************/

/**************Mobile show interest***/
@media screen and (max-width: 850px) and (max-height: 1150px) {
  /**text box **/
  .text-input {
    height: 35px;
    font-size: 12px;
  }
  .react-tel-input .form-control {
    font-size: 12px;
  }
  .size-upload-dc {
    display: none;
  }

  /**dropdown*/
  .custom-dropdown-arrow {
    color: #1b224c;
    width: 0px !important;
    padding-right: 15px;
  }
  .css-1b9fmbm-control {
    min-height: 25px !important;
    height: 35px !important;
    border-color: 1px solid #1b224c31 !important;
  }
  .css-18jj4pb-controll {
    height: 35px !important;
    min-height: 25px !important;
  }
  .css-77ncjv-singleValue {
    font-size: 12px !important;
  }
  .css-b62m3t-container {
    height: 35px !important;
    min-height: 25px !important;
  }
  .css-zso92v-control {
    height: 35px !important;
    min-height: 25px !important;
  }
  .css-18jj4pb-control {
    min-height: 35px !important;
  }
  /**phone input */
  .react-tel-input .form-control {
    height: 35px !important ;
    width: 343px;
    outline: none;
    font-size: 12px;
  }

  .css-1fdsijx-ValueContainer {
    padding: 0px 8px !important;
  }
  .react-tel-input .form-control {
    font-size: 12px !important;
  }

  .words-number {
    font-size: 14px;
  }
  /***aud button*/
  .aud-button {
    padding: 5px;
    font-size: 12px;
  }

  /*p-login**/
  .showInterestForm-inner-cont p {
    font-size: 14px !important;
  }
  .applicant-loginp span {
    font-size: 14px !important;
  }
  /**title*/
  .title {
    font-size: 15px;
  }

  /**rounded button*/
  .rounded-button {
    padding: 7px;
    font-size: 9px;
  }
  .button-icon {
    padding-right: 3px;
    width: 25px;
  }
  .showInterest-subContainer {
    /* height: 95%; */
    width: 95%;
  }
  .residenceCont {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: flex-start;
  }
  .vertical {
    width: 2px;
    height: 85vh;
    background: linear-gradient(
      170deg,
      #1b224c49 50%,
      transparent 50%,
      transparent
    );
    background-size: 1px 20px;
  }
  .vertical {
    display: none;
  }
  .showInterest-subContainer {
    padding: 25px 1px;
    /* height: 70%; was 90 */
    width: 95%;
    flex-direction: column;
    /* justify-content: center;
    gap: 1%; */
  }
  .showInterestLogo {
    display: block;
    width: 50vw;
  }
  .subContainer {
    padding: 10px 10px;
    height: -moz-fit-content;
    height: fit-content;
    width: 95%;
    flex-direction: column;
    justify-content: center;
    gap: 1%;
  }
  .showInterestForm-inner-cont p {
    font-size: 10px !important;
    padding-top: 8px;
  }
  .classfont-show,
  .p-style,
  .underlined-link {
    font-size: 13px;
  }
  .textBox-container label {
    font-size: 11.5px;
  }
  .grid-container2 {
    grid-column-gap: 5px;
  }
  .error-container {
    align-items: center !important;
  }
  .required {
    padding-left: 1px;
    font-size: 17px;
  }
  .modal-container {
    padding: 30px 12px;
  }
  .login-container {
    padding-top: 1rem;
    padding-left: 5px;
    padding-right: 5px;
  }
  .inner-modal-container h2 {
    font-size: 18px;
    color: #020a58;
    font-weight: bold;
  }
  .expandable-card {
    padding: 15px 5px;
    margin: 0px;
    gap: 10px;
  }
  .inner-modal-container .submit-modal-button {
    padding-right: 0px;
  }
  /* .inner-modal-container,
  .modal-conten {
    overflow: scroll;
    max-height: 100vh;
  } */
  .cont {
    overflow: scroll;
    max-height: 40vh;
  }
  .close-icon {
    width: 10%;
  }

  label {
    font-size: 12px;
  }
  .custom-date-input {
    height: 35px;
  }
  .custom-date-input-red {
    height: 35px;
  }
  .custom-date-icon {
    position: absolute;
    right: 10px;
    top: 2;
    width: 15px;
    height: 15px;
  }
  .custom-date-input input[type='date'] {
    font-size: 12px;
  }
  .react-tel-input .form-control {
    font-size: 12px;
  }
  .span-required {
    font-size: 12px;
  }
  .aud-button > .button-icon {
    vertical-align: middle !important;
    width: 18px !important;
  }
  .radio-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
  }
  .radio-buttons-container {
    display: flex;
    gap: 2rem;
    flex-direction: column;
  }
  .radioButton_removeIcon_cont {
    align-items: flex-start;
  }
  .remove_text img {
    width: 12px !important;
  }
  .remove_text h6 {
    font-size: 12px;
  }
  /************************************/
  /*************************/
  /******************/
  /********/
  /***/
  /**************Mobile show register***/
  .registerPage-container {
    margin: 1rem 0.4rem 3rem 0.4rem;
  }
  .register-title {
    font-size: 12px;
  }
  .registerForms-container {
    padding: 1rem 0.2rem;
  }
  .form-subcontainers {
    gap: 0.8rem;
  }
  .textBox-container {
    font-size: 10px !important;
  }
  .close-document-icon {
    width: 12px;
  }
  .select-via-email img {
    width: 20px;
    height: 20px;
  }
  .select-via-email {
    gap: 10px;
    height: 50px;
    padding: 20px;
  }
  /******form step 1***********/
  .grid-personal1-cont,
  .grid-personal2-cont,
  .grid-mailing-cont,
  .grid-consent1-cont {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  .grid-personal-emirates-cont {
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    padding-top: 10px;
  }
  .form-subcontainer {
    gap: 2rem;
  }
  .grid-programInfo-cont {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }
  .transcript-mailing-cont {
    grid-template-columns: 1fr 1fr;
  }
  .moreInfo-grid a,
  .moreInfo-grid p {
    font-family: Raleway;
    font-size: 12px;
    padding-top: 5px;
  }
  .checkbox-container p {
    font-size: 12px;
  }
  .checkmark,
  .red-checkmark {
    height: 16px;
    width: 16px;
  }

  .checkbox-container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 9px;
    border-width: 0 2px 2px 0;
  }

  .bulleted-consent-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .radio-input label {
    font-size: 12px !important;
  }
  .radio-input input {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #113161;
  }
  .radio-input .circle {
    position: relative;
    top: 0em;
    right: 13px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .radio-input input:checked {
    border: 1px solid #113161;
  }
  .radio-buttons-container {
    gap: 3px;
  }
  .consent-text,
  .bulleted-text li {
    font-size: 12px;
  }
  .studentInfo-subCont {
    grid-template-columns: 1fr;
  }
  /*******form step 2***/
  .grid-academic-cont,
  .grid-acd-cont {
    grid-template-columns: 1fr 1fr;
  }
  .grid-academic4-cont {
    grid-template-columns: 1fr;
  }
  .img-text-container p {
    font-size: 10px;
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .size-upload-dc p {
    font-size: 9px;
  }

  .file-input-container,
  .file-input-container-upl {
    height: 40px !important;
  }
  /******payment*******/
  .payment-subcontainer {
    width: 100%;
  }
  .card_dropdown_cont {
    flex-direction: column;
  }
  .select-via-email h3 {
    font-size: 13px;
  }
  .expandable-card {
    padding: 10px 5px;
    margin: 10px 0;
    gap: 15px;
  }
  .inner-modal-container h2 {
    font-size: 15px;
    color: #020a58;
    font-weight: bold;
  }
  .close-icon img {
    width: 30px;
  }
  .applicant-loginp span {
    font-size: 10px !important;
  }
  .bigImage {
    height: 30px;
  }
  /********Stepper responsive*********/
  .aud-app-header {
    display: flex;
    gap: 10px;
    padding: 1rem;
    flex-direction: column;
    justify-content: normal;
    align-items: normal;
  }
  .inActiveLine {
    display: none;
  }
  /* .aud-header-logo{
  padding-left: 15px;
} */
  .checkedLogo {
    height: 20px;
  }
  .hide-titile {
    display: none;
    padding: 0rem 10px 0 5px;
  }
  .stepper {
    display: flex;
    justify-content: normal;
    width: 100%;
  }
  .stepper .step {
    padding: 0px 0px 0px 6px;
    margin: 0px 2px 0px 2px;
    height: 20px;
    width: 20px;
  }
  .exit-container {
    padding: 25px 0px;
    font-size: 12px;
  }
  .exit-container p {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 800px) {
  .button-cont-register {
    gap: 0.5rem;
    display: flex;
    margin-left: 0px;
    flex-direction: column-reverse;
  }
  .applications-table-cont {
    width: 95vw;
  }
}
